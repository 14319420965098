.header {
  height: 4rem;
  padding: 0.5rem;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background: white;
  box-shadow: 5px 1px 5px 0px rgba(0, 0, 0, 0.11);

  & > button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  .header__icon-button {
    background-color: var(--color-deep-slate-blue);
    border-radius: 25%;
    border: none;
    top: auto;
    height: 3.5rem;
    width: 3rem;
    transition: all 0.3s;

    &:hover {
      transform: scale(0.9);
    }

    label {
      font-size: var(--fs-smaller);
      font-weight: 600;
      color: white;
      cursor: pointer;
    }
  }

  .header__icon-button-nobg {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-size: var(--fs-smaller);
      font-weight: 600;
      color: black;
      cursor: pointer;
    }
  }

  .icon {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }

  .add-icon {
    height: inherit;
  }

  .statistics-icon {
    right: 1rem;
  }

  .filter-icon {
    fill: white;
    position: relative;
  }
  .share-icon {
    left: 1rem;
  }
}

.logo {
  height: 2.5rem;
  fill: var(--color-deep-slate-blue);
  cursor: pointer;
  margin: 0 auto;
}

.header__logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header__icon-container {
  position: relative;
}

.header__notification {
  position: absolute;
  left: 30px;
  color: var(--color-white);
  padding: 0 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-red);
  border-radius: 10px;
  font-size: 0.2rem;
}

.header__share-icon-container {
  position: absolute;
  left: 1rem;
  top: 50%; /* Vertically center the icon container */
  transform: translateY(-50%); /* Adjust for vertical centering */
}

.header__notification-small {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, 50%); /* Center the notification */
  color: var(--color-white);
  padding: 0 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-red);
  border-radius: 10px;
  font-size: 0.5rem;
}
