.upload-image-area {
  width: 100%;
}

.upload-image-area__button {
  background: var(--color-deep-slate-blue);
  cursor: pointer;
  border-radius: 100%;
  margin: 0 auto 1rem auto;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s;

  &:hover .upload-image-area__icon {
    transform: scale(0.95);
  }
  &:hover {
    transform: scale(0.95);
  }

  & > .upload-image-area__icon {
    fill: white;
    height: 2rem;
    width: 2rem;
    transition: all 0.3s;
  }
}

.upload-image-area__file-input {
  display: none;
}
