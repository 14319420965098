@import "../../styles/variables";

.inspo-page__container {
  margin: 2rem auto 0 auto;

  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: $breakpointTablet) {
    max-width: 18rem;
  }

  @media (max-width: $breakpointPhone) {
    width: 100vw;
    max-width: 100vw;
    margin: auto;
  }
}

.inspo-page__container > .logo {
  height: 3rem;
  padding: 2rem 3.5rem 3rem 3.5rem;
}

.inspo-page__ring-list .ring-list__thumbnail {
  width: 1em;
}

.inspo-page__card-container {
  max-width: 18rem;
  flex: 1 1;
  order: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > button {
    margin-top: 5rem;
  }
}

.fade-enter {
  opacity: 0;
  transform: translateX(200px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 200ms,
    transform 200ms;
}

.fade-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(200px);
  transition:
    opacity 200ms,
    transform 200ms;
}
