.upload-image {
  padding: 2rem;
  width: 25rem;

  h1 {
    color: black;
    font-size: 1.5rem;
  }
  .upload-image__subtitle {
    font-size: 0.8rem;
    color: black;
    margin-bottom: 1rem;
  }
}

.upload-image__terms-text {
  font-size: var(--fs-smaller);
  color: black;

  & > a {
    color: gray;
  }
}

.upload-image__container {
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  border-radius: 10px;

  & > button {
    width: 100%;
  }
  & > h1.success-message {
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
    color: black;
  }
  & > p {
    text-align: left;
    color: black;
  }
}

progress {
  accent-color: #b47b4f;
}
