@import "../../styles/variables";

.admin-page {
  background: var(--color-lightgrey);

  .admin-page__rings-container__count {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    padding: 1rem 0 1rem 0;
  }

  .admin-page__delete-button {
    position: absolute;
    right: 0;
    background: none;
    padding: 0.2rem;
    border: none;
  }

  .admin-page__link-icon {
    fill: white;
    background: black;
    border-radius: 4px;
  }
}

.admin-page__image-container {
  padding: 1rem 0;
  width: 100%;
  min-height: 100vh;
  max-width: 57rem;
  padding-left: 0.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem 1rem;
  @media (max-width: $breakpointTablet) {
    padding: 0.2rem 0;
    max-width: 24.6rem;
    gap: 0.2rem 0.2rem;
  }

  @media (max-width: 24.6rem) {
    padding: 0.2rem 0;
    max-width: 24.6rem;
    gap: 0.2rem 0.2rem;
    justify-content: center;
  }

  .box {
    position: relative;
    background-color: #fff;
    width: 18rem;
    height: 26rem;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.16);
    background-size: cover;
    background-position: center;

    @media (max-width: $breakpointTablet) {
      width: 8rem;
      height: 8rem;
    }

    &.selected {
      border: black solid 2px;
    }

    & > span {
      background: black;
      border-radius: 4px;
      padding: 0.2rem;
    }
  }
}
