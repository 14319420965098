.sign-in-email {
  width: 18rem;
  display: flex;
  flex-direction: column;

  .title {
    margin: 10px 0;
  }
  .buttons {
    justify-content: space-between;
    display: flex;
  }
}
