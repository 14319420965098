.save-to-collection-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent; // No shading on the background
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-to-collection-modal__content {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80vw;
  max-width: 500px;
  overflow: hidden;
}

.save-to-collection-modal__header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
  color: black;
}

.save-to-collection-modal__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.save-to-collection-modal__need-login {
  color: black;

  > a {
    color: var(--color-burnt-sienna);
  }
}
