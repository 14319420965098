.comments-view {
  display: flex;
  flex-direction: column;
  margin-bottom: 4.5rem;

  .comments-view__comments-container {
    overflow: scroll;
    margin-bottom: 1rem;
  }

  .comments-view__input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;

    .comments-view__user-avatar {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      margin-right: 1rem;

      .comments-view__user-avatar-image {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }

    .comments-view__input-field {
      border: none;
      border-bottom: 1px solid var(--color-deep-slate-blue);
      padding: 0.5rem 1rem 0.5rem 0.2rem;
      font-size: 1rem;
      flex: 1;
      resize: none;
    }

    .comments-view__submit-button {
      background: var(--color-deep-slate-blue);
      color: white;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem 0.5rem 1rem;
      cursor: pointer;
      font-weight: 800;
      font-size: 1rem;
      margin-left: 1rem;
    }
  }
}
