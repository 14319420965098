@import "../../styles/variables";

.uploader-tag {
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 0.5rem;
  width: 100%;
  padding-left: 0.5rem;
  filter: drop-shadow(0.5px 0.5px 2.5px gray);

  span {
    margin-left: 0.5rem;
    color: white;
    font-weight: 600;
  }

  .profile-icon {
    width: 2rem;
    height: 2rem;
    fill: white;
    border-radius: 100%;
  }
}
