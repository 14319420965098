@import "../../styles/variables";

.collection-card__card {
  position: absolute;
  width: 12rem;
  height: 18rem;

  @media (max-width: $breakpointPhone) {
    width: 9rem;
    height: 14rem;
  }
}

.collection-card__card-container {
  width: 12rem;
  height: 18rem;

  & > div:nth-last-child(3) {
    translate: 50px 0px;
    scale: 0.8;
  }

  & > div:nth-last-child(2) {
    translate: 25px 0px;
    scale: 0.9;
  }

  & > div:last-child {
    translate: 0px 0px;
    scale: 1;
  }

  @media (max-width: $breakpointPhone) {
    width: 9rem;
    height: 14rem;

    & > div:nth-last-child(3) {
      translate: 0px 0px;
      scale: 0.8;
    }

    & > div:nth-last-child(2) {
      translate: 0px 0px;
      scale: 0.9;
    }

    & > div:last-child {
      translate: 0px 0px;
      scale: 1;
    }
  }
}

.collection-card__title {
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 600;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 90%;
  padding: 5px 10px;
}

.collection-card__button {
  display: inline-block; /* Ensures the button sizes correctly */
  padding: 10px 20px; /* Adds space inside the button */
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px; /* Font size for better readability */
  font-weight: 600; /* Makes the text bold */
  color: var(--color-white); /* Text color */
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Semi-transparent background for better readability */
  border: 1.5px solid var(--color-white);
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition:
    background-color 0.3s,
    transform 0.2s; /* Smooth transition for hover effects */

  &:hover {
    background-color: var(
      --color-burnt-sienna-dark
    ); /* Darker background on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
  }

  &:focus {
    outline: none; /* Removes default focus outline */
    box-shadow: 0 0 0 3px rgba(184, 128, 93, 0.5); /* Custom focus outline */
  }

  &:active {
    background-color: var(
      --color-burnt-sienna-darker
    ); /* Even darker background when button is clicked */
    transform: translateY(0); /* Removes the lift effect */
  }

  &:disabled {
    background-color: #ccc; /* Light gray background when disabled */
    cursor: not-allowed; /* Shows a not-allowed cursor */
    opacity: 0.6; /* Slightly faded appearance */
  }
}
