.sidebar {
  height: 100%;
  width: 20rem;
  z-index: 15;
  position: fixed;
  z-index: 15;
  right: 0px;
  top: 0px;
  background: white;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.16);
  padding: 0.5rem;

  .sidebar__contents {
    overflow: auto;
    height: calc(100% - 140px);
  }

  & > div > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.5rem;
  }

  h3 {
    text-align: center;
    color: black;
    margin: 0 auto;
  }

  .sidebar__footer {
    width: 100%;
    margin-left: -50%;
    position: absolute;
    left: 50%;
    bottom: 10px;
  }

  button.primary {
    width: 100%;
  }

  .sidebar_header {
    width: 100%;
    display: flex;
    padding: 1rem;
  }

  .back-icon {
    cursor: pointer;
    position: absolute;
    left: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar label {
  color: black;
}

.sidebar__report-text {
  font-size: var(--fs-smaller);
  color: black;

  & > a {
    color: gray;
  }
}
