@import "../../styles/variables";

.box {
  position: relative;
  background-color: #fff;
  width: 18rem;
  height: 18rem;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.16);
  background-size: cover;
  background-position: center;

  @media (max-width: $breakpointTablet) {
    width: 8rem;
    height: 8rem;
  }

  &.empty {
    border: solid 1px var(--color-grey);
    background: transparent;
  }

  .box__likes-box {
    position: absolute;
    background: white;
    border-radius: 0.5rem;
    padding: 0.2rem 0.4rem;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    color: black;
    margin: 0 0 0.1rem 0.1rem;

    & > span {
      margin: 0 0.2rem;

      @media (max-width: $breakpointTablet) {
        font-size: var(--fs-small);
      }
    }
  }
  .box__add-button {
    background: var(--color-darkgrey);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    transition: all 0.3s;
    border: none;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: scale(0.95) translate(-50%, -50%);
    }
  }
  .box__add-icon {
    fill: var(--color-lightgrey);
    background: var(--color-darkgrey);
    cursor: pointer;
  }
  .box__wait-icon {
    fill: var(--color-grey);
    height: 1.3rem;
    width: 1.3rem;

    @media (max-width: $breakpointTablet) {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
  .box__like-icon {
    background: var(--color-deep-slate-blue);
    fill: white;
    border-radius: 100%;
    padding: 0.2rem;
    width: 1.3rem;
    height: 1.3rem;

    @media (max-width: $breakpointTablet) {
      width: 1.1rem;
      height: 1.1rem;
    }
  }

  .box__more-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .box__unlike-button {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    background: none;
    border: none;

    .saved-icon {
      fill: var(--color-burnt-sienna);
      filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.3));
    }
    .saved-icon:hover {
      stroke: var(--color-darkgrey);
      stroke-width: 1px;
      stroke-dasharray: 2, 2;
      stroke-linejoin: round;
      fill: none;
    }
  }
  .box__shop-link {
    border: none;
    position: absolute;
    background: white;
    padding: 0.2rem 0.4rem;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
  }

  .tag-icon {
    fill: black;
    width: 20px;
    height: 30px;

    @media (max-width: $breakpointPhone) {
      width: 20px;
      height: 30px;
    }
  }
}
