.text-input {
  transition: all 0.25s;
  width: 100%;

  &:disabled {
    background: none;
    border: none;
    color: black;
  }
}
