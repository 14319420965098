.paper {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
  padding: 2rem;
}

.vl {
  border-left: 1px solid var(--color-grey);
  height: 40px;
  position: relative;
  margin: 0 1rem 1rem 1rem;
}
