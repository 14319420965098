.profile-image-holder {
  flex: 1;
  max-width: 15rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &__holder {
    height: 0;
    width: 100%;
    border-radius: 50%;
    padding-bottom: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__button {
    overflow: hidden;
    background: linear-gradient(0deg, black, transparent);
    cursor: pointer;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    fill: gray;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  &__save-button {
    position: absolute;
    border: 1px solid var(--color-lightgrey);
    background: white;
    border-radius: 0.2rem;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__icon {
    fill: white;
    height: 2rem;
    width: 2rem;
    transition: all 0.3s;
  }

  &:hover .profile-image-holder__button {
    visibility: visible;
    opacity: 1;
  }
  .hidden {
    display: none;
  }
}
