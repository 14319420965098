@import "../../styles/variables";

.collections-container {
  display: flex;
  gap: 4rem 4rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $breakpointPhone) {
    gap: 2rem 2rem;
    justify-content: space-between;
    align-items: center;
  }

  .new-collection-button {
    width: 12rem;
    height: 18rem;
    border-radius: 2rem;
    margin: 0 auto;
    border: none;
    box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.16);
    @media (max-width: $breakpointPhone) {
      width: 9rem;
      height: 14rem;
    }
  }
}
