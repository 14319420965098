@import "../../styles/variables";

.card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 18rem;
  height: 24rem;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: $breakpointPhone) {
    width: 100vw;
    max-width: 100vw;

    // Default height for .card on mobile screens
    height: calc(var(--vh, 1vh) * 100 - 8.5rem);

    &.collection {
      // Adjusted height for .card with .collection class on mobile screens
      height: calc(var(--vh, 1vh) * 100 - 8.5rem - 35px);
    }
  }

  .next-image,
  .previous-image {
    background: none;
    position: absolute;
    bottom: 10rem;
    border: none;
    cursor: pointer;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4));
    @media (max-width: $breakpointPhone) {
      bottom: calc((var(--vh, 1vh) * 100 - 8.5rem) / 2 - 2rem);
    }
  }

  .previous-image {
    left: 0;
  }

  .next-image {
    right: 0;
  }

  .card__sales-tag,
  .card__dislike,
  .card__like {
    background: white;
    position: absolute;
    bottom: 0;
    border: none;
    cursor: pointer;
  }

  .card__dislike {
    border-radius: 0 20px 0 20px;
    left: 0;
  }
  .card__info {
    color: white;
    font-weight: 800;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    border: none;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .card__like {
    border-radius: 20px 0 20px 0;
    right: 0;
  }

  .card__sales-tag {
    border-radius: 0 20px 20px 0;
    bottom: 5rem;
    left: 0;
    // transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    // width: 2rem;
    overflow: none;

    &:hover {
      .card__hover-text {
        display: block;
      }
      // width: 7rem;
    }
  }

  .card__hover-text {
    display: none;
    padding-left: 0.5rem;

    &.visible {
      display: block;
    }
  }

  .arrow-icon {
    fill: white;
    width: 25px;
    height: 35px;
  }

  .tag-icon,
  .cross-icon,
  .heart-icon {
    fill: black;
    width: 20px;
    height: 30px;

    @media (max-width: $breakpointPhone) {
      width: 9vw;
      height: 6vh;
    }
  }
}

.swipe-container {
  display: flex;
  align-items: center;
}

.card-container {
  width: 90vw;
  max-width: 18rem;
  height: 24rem;
  margin: 0 auto;

  & > div {
    transition: all 0.1s;
  }

  & > div:first-child {
    translate: 0 80px;
    scale: 0.7;
  }

  & > div:nth-child(n) {
    translate: 0 80px;
    scale: 0.7;
  }

  & > div:nth-child(8) {
    translate: 0 100px;
    scale: 0.8;
  }
  & > div:nth-child(9) {
    translate: 0 50px;
    scale: 0.9;
  }
  & > div:last-child {
    translate: 0px 0px;
    scale: 1;
  }

  @media (max-width: $breakpointPhone) {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100 - 8.5rem);
    max-width: 100vw;

    & > div:nth-child(8) {
      translate: 0 135px;
      scale: 0.8;
    }
    & > div:nth-child(9) {
      translate: 0 70px;
      scale: 0.9;
    }
    & > div:last-child {
      translate: 0px 0px;
      scale: 1;
    }
  }
}

.swipe {
  position: absolute;
}

.card-container__no-more-rings {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &.collection {
    color: black;
    border-radius: 2rem;
    background-color: var(--color-white);
    overflow: auto;
    padding: 1rem;

    a {
      color: var(--color-burnt-sienna);
    }
  }
}

.refresh-icon {
  fill: white;
  cursor: pointer;
  transition: all 0.3s linear 0s;
  margin-top: 2rem;
  height: 5rem;
  &:hover {
    transform: rotate(-420deg);
  }
}

.bounce .active {
  animation: bounce 1s ease;
  animation-delay: 1s;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
