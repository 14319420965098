.toast {
  position: absolute;
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  z-index: 999;
  font-size: 17px;
  opacity: 0;
  transition:
    opacity 0.6s,
    visibility 0.6s;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
