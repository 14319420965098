.service-interuption {
  padding: 0.5rem;
  text-align: center;
  font-weight: 500;
  background: gray;
}

.base-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
