.login {
  background: url("../../assets/ring_image.png") no-repeat center black;
  width: 100%;
  height: 100vh;
}

.login__dark-overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 4rem);
  position: absolute;
}

.sign-in-and-sign-up {
  margin: 4rem auto 0 auto;
  width: 26rem;
  max-width: 100%;
  padding: 0 2rem;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.login__signup {
  font-size: var(--fs-small);
  text-decoration: none;
  margin-top: 2rem;
}
