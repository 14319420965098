.spinner-overlay {
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}

.spinner-text {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  color: var(--spinner-text-color);
}

.light-theme {
  --spinner-text-color: var(--color-darkergrey);
}

.dark-theme {
  --spinner-text-color: var(--color-darkwhite);
}
