.save-to-collection-modal__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: cover;
  }

  .item-name {
    font-size: 1rem;
    color: black;
    font-weight: 500;
  }
}
