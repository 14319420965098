.login-button {
  min-width: 165px;
  width: 18rem;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: var(--color-burnt-sienna);
  color: white;
  font-family: "Roboto", sans-serif;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 0.5rem;

  &:hover {
    background-color: var(--color-burnt-sienna-dark);
    color: white;
  }

  &.google-sign-in {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: gray;
    position: relative;

    & > .icon {
      width: 1rem;
      position: absolute;
      left: 1rem;
    }
  }
}
