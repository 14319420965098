.statistics {
  overflow-y: scroll;
  max-height: 80vh;
  width: 100%;
  padding: 0 0.5rem;
}
.statistics__container {
  margin-bottom: 1rem;
  width: 100%;

  &:last-child {
    margin-bottom: 2rem;
  }
}
.statistics__title {
  color: var(--color-grey);
  margin-bottom: 2rem;
}
