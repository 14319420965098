.upload-image-form {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text-button {
    margin: 1rem 0 1rem 0;
  }

  .upload-image-form__container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  form {
    width: 15rem;
    margin-bottom: 0.5rem;

    & > .button {
      width: 100%;
    }
    .red {
      color: red;
    }
  }

  .form-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1rem;

    & > select {
      padding: 0.4rem;
      margin: 0.2rem 0;
    }
    & > input {
      padding: 0.4rem;
      margin: 0.2rem 0;
    }
  }
  .upload-image-form__checkbox {
    margin-bottom: 1rem;
  }

  .upload-image-form__card-container {
    width: 100%;
  }

  .upload-image-form__card-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
  }

  .upload-image-form__card {
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    position: relative;

    & > .image-card {
      margin: 0 auto;
      width: 50px;
      height: 60px;
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
}
