.comment {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  &.response {
    margin-left: 3rem;
  }

  .comment__user-avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    margin-right: 1rem;

    .comment__user-avatar-image {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .comment__details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    .comment__user-name {
      font-weight: 800;
      font-size: 0.8rem;
    }

    .comment__date {
      margin-left: 0.5rem;
      font-size: 0.8rem;
      color: var(--color-darkgrey);
    }

    .comment__text {
      font-size: 1rem;
    }

    .comment__reply {
      margin-left: -0.3rem;
      margin-top: 0.5rem;
      font-size: 0.8rem;
      color: var(--color-darkgrey);
      width: fit-content;
    }
    
  }
}
