@import "../../styles/variables";

.liked-rings__image-container {
  padding: 1rem 0;
  width: 100%;
  min-height: 100vh;
  max-width: 57rem;
  padding-left: 0.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: baseline;
  gap: 1rem 1rem;
  @media (max-width: $breakpointTablet) {
    padding: 0.2rem 0;
    max-width: 24.6rem;
    gap: 0.2rem 0.2rem;
    align-content: baseline;
  }

  @media (max-width: 24.6rem) {
    padding: 0.2rem 0;
    max-width: 24.6rem;
    gap: 0.2rem 0.2rem;
    justify-content: center;
    align-content: baseline;
  }
}
