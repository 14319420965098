.filter-button {
  display: inline-block;
  padding: 0.4em 0.8em;
  background: var(--color-grey);
  border: 0;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 300;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  margin: 0.2rem;

  &.reverse {
    background-color: var(--color-white);
    border: 1px solid var(--color-grey);
  }
}

.filter-button.active {
  background: var(--color-deep-slate-blue);

  &.reverse {
    background-color: var(--color-white);
    color: var(--color-deep-slate-blue);
    border: 1px solid var(--color-deep-slate-blue);
  }
}

.filter-button:focus {
  outline: 0 none;
}
