.save-to-collection-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent; // No shading on the background
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-to-collection-modal__content {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80vw;
  max-width: 500px;
  overflow: hidden;
}

.save-to-collection-modal__header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
  color: black;
}

.save-to-collection-modal__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.save-to-collection-modal__submit-button {
  display: inline-block; /* Ensures the button sizes correctly */
  padding: 10px 20px; /* Adds space inside the button */
  margin-top: 15px; /* Adds space above the button */
  font-size: 16px; /* Font size for better readability */
  font-weight: 600; /* Makes the text bold */
  color: var(--color-white); /* Text color */
  background-color: var(--color-burnt-sienna); /* Background color */
  border: none; /* Removes default border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition:
    background-color 0.3s,
    transform 0.2s; /* Smooth transition for hover effects */

  &:hover {
    background-color: var(
      --color-burnt-sienna-dark
    ); /* Darker background on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
  }

  &:focus {
    outline: none; /* Removes default focus outline */
    box-shadow: 0 0 0 3px rgba(184, 128, 93, 0.5); /* Custom focus outline */
  }

  &:active {
    background-color: var(
      --color-burnt-sienna-darker
    ); /* Even darker background when button is clicked */
    transform: translateY(0); /* Removes the lift effect */
  }

  &:disabled {
    background-color: #ccc; /* Light gray background when disabled */
    cursor: not-allowed; /* Shows a not-allowed cursor */
    opacity: 0.6; /* Slightly faded appearance */
  }
}
