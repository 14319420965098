@import "../../styles/variables";

.collection__tag {
  font-weight: 800;
  font-size: 1rem;
  padding: 0.5rem;
}

.collection__container {
  margin: 2rem auto 0 auto;

  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: $breakpointTablet) {
    max-width: 18rem;
  }

  @media (max-width: $breakpointPhone) {
    width: 100vw;
    max-width: 100vw;
    margin: auto;
  }

  .inspo-page__card-container {
    max-width: 18rem;
    flex: 1 1;
    order: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
