.sign-up-page {
  width: 100%;
}

.sign-up-page__ring-bg {
  background: url("../../assets/ring_image.png") no-repeat center black;
  min-height: 100vh;
}

.sign-up-page__dark-overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 1rem;
}
