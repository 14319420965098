@import "../../styles/variables";

.promo-body__container {
  background-color: var(--color-deep-slate-blue-dark);
  margin-top: 4rem;
  display: flex;
  width: 100%;
  color: var(--color-white);
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  h1 {
    text-align: center;
    margin-bottom: 4rem;
  }

  .promo-body__logos {
    display: flex;
    align-items: center;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .promo-body__logo {
    width: 14rem;
    transition: all 0.5s;
    fill: white;
    margin: 1rem;
    &:hover {
      transform: scale(0.8);
    }
    @media (max-width: $breakpointTablet) {
      width: 7rem;
    }
  }
}
