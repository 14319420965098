@import "../../styles/variables";

.user {
  background: var(--color-lightgrey);
  width: 100%;
  .user__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: var(--color-white);
    border-radius: 0 0 2rem 2rem;
  }
  .user__header-row {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 0.5rem;
  }
  .user__header-row.hidden {
    height: 10rem;
    overflow: hidden;
    align-items: flex-start;
  }
  .user__header-hidden-gradient {
    position: absolute;
    height: 10rem;
    width: 100%;
    background: linear-gradient(0deg, white 30%, transparent);
    bottom: 0;
    cursor: pointer;
    border-radius: 0 0 2rem 2rem;

    &.hidden {
      display: none;
    }
  }
  .user__items {
    padding-left: 4rem;
  }

  table {
    flex: 2;
    max-width: 20rem;
    td:nth-child(2) {
      width: 3rem;
      height: 2rem;
    }
  }

  .user__edit-icon {
    fill: var(--color-grey);
    cursor: pointer;
  }

  .user__link-icon {
    fill: var(--color-white);
  }

  .user__link-box {
    width: fit-content;
    padding: 0.3rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    background: var(--color-deep-slate-blue);

    .text-input:disabled {
      color: white;
    }

    a {
      padding-left: 0.2rem;
      text-decoration: none;
      font-size: var(--fs-small);
    }

    span {
      padding-left: 0.2rem;
      text-decoration: none;
      font-size: var(--fs-small);
      cursor: pointer;
    }
  }
  .user__body {
    padding-top: 1rem;
    background: var(--color-lightgrey);
    min-height: calc(100vh - 150px);
    padding-bottom: 5rem;

    .user__body-tabs {
      display: flex;
      flex-direction: row;

      @media (max-width: $breakpointPhone) {
        flex-direction: column;
      }

      & > h4 {
        margin-right: 1rem;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid var(--color-darkgrey);
        }
      }
    }
  }
  .user__body-section {
    max-width: 57rem;
    margin: 0 auto;
    h4 {
      color: var(--color-darkgrey);
    }
  }
  .user__image-container {
    padding: 1rem 0;
    width: 100%;
    padding-left: 0.5rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem 1rem;
    @media (max-width: $breakpointTablet) {
      padding: 0.2rem 0;
      max-width: 24.6rem;
      gap: 0.2rem 0.2rem;
    }

    @media (max-width: 24.6rem) {
      padding: 0.2rem 0;
      max-width: 24.6rem;
      gap: 0.2rem 0.2rem;
      justify-content: center;
    }
  }
}
