.carat-size {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &:hover .carat-size__progress {
    background: var(--color-deep-slate-blue-dark);
  }

  &:hover .carat-size__diamond-thumb {
    fill: var(--color-deep-slate-blue-dark);
  }

  &:hover .carat-size__label {
    color: var(--color-deep-slate-blue-dark);
  }

  .carat-size__bar {
    min-width: 300px;
    position: relative;
    height: 4px;
    background: var(--color-grey);
  }
  .carat-size__progress {
    height: 4px;
    background: var(--color-deep-slate-blue);
  }
  .carat-size__diamond-thumb {
    position: absolute;
    margin-top: -25px;
    fill: var(--color-deep-slate-blue);
  }

  .carat-size__diamond-ledger {
    fill: var(--color-grey);
  }
  .carat-size__diamond-ledger.left {
    transform: scale(0.6);
    margin-top: -12px;
    margin-right: 5px;
  }
  .carat-size__diamond-ledger.right {
    transform: scale(1.5);
    margin-top: -32px;
    margin-left: 20px;
  }
  .carat-size__label {
    position: absolute;
    color: var(--color-deep-slate-blue);
    padding: 0.1rem 0.2rem;
    margin-top: 4px;
    border-radius: 5px;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
  }
}
