.modal-background {
  background: white;
  color: black;
  padding: 1rem;
  border-radius: 4px;
}

.save-modal {
  p {
    font-size: 0.8rem;
  }

  button {
    width: 100%;
  }

  .terms {
    font-size: 0.5rem;
    color: gray;

    a {
      color: black;
    }
  }

  .error {
    font-size: 0.5rem;
    color: red;
  }
}

.thank-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: var(--color-burnt-sienna);
  }
}
