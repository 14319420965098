.custom-listitem {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  .custom-listitem__container {
    width: 100%;
    padding: 0.5rem;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 0.6rem;

    &:hover {
      background: var(--color-lightgrey);
    }

    &:hover .custom-listitem__heart {
      fill: var(--color-lightgrey);
    }
  }
  .custom-listitem__icon {
    height: 40px;
    margin: 0.2rem 0.6rem 0.2rem 0.2rem;
  }
  .custom-listitem__nameandcount {
    display: flex;
    flex-direction: column;
    flex: 1;
    .custom-listitem__name {
      font-weight: 600;
    }
    .custom-listitem__count {
      color: var(--color-grey);
      font-size: var(--fs-small);
    }
  }
  .custom-listitem__percentage {
    color: var(--color-deep-slate-blue);
    display: flex;
    align-items: center;
    font-weight: 600;
    & > span {
      width: 2.8rem;
      text-align: right;
    }
    .custom-listitem__heart {
      background: var(--color-deep-slate-blue);
      padding: 0.3rem;
      border-radius: 100%;
      fill: var(--color-white);
    }
  }
}
