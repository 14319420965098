.footer {
  background: var(--color-white);
  border-radius: 2rem 2rem 0 0;
  width: 100%;
  max-width: 380px;
  height: 4rem;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  z-index: 1;

  .footer__option {
    color: var(--color-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .footer__icon-container {
      position: relative;
    }
    .footer__notification {
      position: absolute;
      right: -5px;
      top: -5px;
      color: var(--color-white);
      padding: 0 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-red);
      border-radius: 10px;
      font-size: 0.2rem;
    }

    .home-icon {
      fill: var(--color-grey);

      &.active {
        fill: var(--color-deep-slate-blue);
      }
    }
    .footer__add-icon-button {
      background: var(--color-deep-slate-blue);
      cursor: pointer;
      border-radius: 100%;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
      height: 3rem;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      .add-icon {
        fill: var(--color-white);
        width: 2rem;
        height: 2rem;
        transition: all 0.3s;
      }

      &:hover .add-icon {
        transform: scale(0.95);
      }
      &:hover {
        transform: scale(0.95);
      }
    }

    .saved-icon {
      fill: var(--color-grey);
      transition: 0.5s ease-in all;

      &.active {
        fill: var(--color-deep-slate-blue);
      }

      &.ring-liked {
        fill: var(--color-red);
        transform: scale(1.1);
      }
    }

    .profile-icon {
      fill: var(--color-grey);
      transition: 0.5s ease-in all;

      &.active {
        fill: var(--color-deep-slate-blue);
      }
    }
    label {
      font-size: var(--fs-smaller);
      font-weight: 600;

      &.active {
        color: var(--color-deep-slate-blue);
      }
    }
  }
  .footer__left {
    flex: 1;
  }
  .footer__right {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
  }
}
