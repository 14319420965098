a,
a:link,
a:visited,
a:hover,
a:active {
  color: white;
}

.smaller {
  font-size: var(--fs-smaller);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-deep-slate-blue);
  color: var(--color-white);
  max-width: 100vw;
  min-height: 100%;
}
