@import "../../styles/variables";

.ring-detailed-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .ring-detailed-view__top-bar {
    height: 2rem;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.is-modal {
      width: 90vw;
    }
  }

  .ring-detailed-view__back-button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
  }

  .ring-detailed-view__save-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 60px;
    padding: 0.5rem;
    border-radius: 4rem;
    background-color: var(--color-burnt-sienna);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: var(--color-burnt-sienna-dark);
    }
    &:active {
      background-color: var(--color-burnt-sienna-darker);
    }
    &:focus {
      outline: none;
    }
    &.black {
      background: black;
    }
  }
  .ring-detailed-view__container.is-modal {
    width: 90vw;
    height: 90vh;

    img {
      object-fit: cover;
    }
  }

  .ring-detailed-view__container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: $breakpointTablet) {
      flex-direction: column-reverse;
    }

    .ring-detailed-view__info {
      position: relative;
      padding: 3rem 1rem 2rem 1rem;
      background: white;
      color: black;
      flex: 0.5;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 4rem);
      overflow: auto;

      @media (max-width: $breakpointTablet) {
        flex: 1;
        width: 100%;
        height: 100%;
      }

      .filter-button {
        display: flex;
        align-items: center;
      }

      .ring-detailed-view__filter-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .ring-detailed-view__add-tag {
        display: flex;
        align-items: center;
        background-color: var(--color-deep-slate-blue);
        border: 0;
        fill: var(--color-white);
        border-radius: 4px;
        width: -moz-fit-content;
        width: fit-content;
        cursor: pointer;
        margin: 0.2rem;
        min-height: 31.63px;

        &:hover {
          fill: var(--color-deep-slate-blue);
          background-color: var(--color-white);
          border: 1px solid var(--color-deep-slate-blue);
        }

        &.confirm {
          background-color: var(--color-burnt-sienna);
          fill: var(--color-white);

          &:hover {
            fill: var(--color-burnt-sienna);
            background-color: var(--color-white);
            border: 1px solid var(--color-burnt-sienna);
          }
        }
      }

      .like-icon {
        background-color: var(--color-deep-slate-blue);
        fill: var(--color-white);
        border-radius: 100%;
        padding: 0.2rem;
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 0.5rem;
      }
    }

    .ring-detailed-view__date {
      font-size: 0.8rem;
      color: var(--color-darkgrey);
      margin-bottom: 0.5rem;
    }

    .ring-detailed-view__image-container {
      background-color: black;
      flex: 1;
      max-width: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media (max-width: $breakpointTablet) {
        height: auto;
        width: 100%;
        max-width: 100%;
      }
    }
    img {
      background-color: black;
      flex: 1;
      height: 100%;
      max-width: 100%;
      max-height: calc(100vh - 4rem);
      object-fit: contain;

      @media (max-width: $breakpointTablet) {
        height: auto;
        width: 100%;
        max-width: 100%;
      }
    }

    p {
      font-weight: 400;
    }

    a {
      color: var(--color-burnt-sienna);
      text-decoration: none;
    }

    .ring-detailed-view__next-image,
    .ring-detailed-view__previous-image {
      background: black;
      border-radius: 100%;
      height: 40px;
      width: 40px;
      position: absolute;
      border: none;
      cursor: pointer;
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4));
    }

    .ring-detailed-view__previous-image {
      left: 0;
    }

    .ring-detailed-view__next-image {
      right: 0;
    }
  }

  .ring-detailed-view__icon {
    fill: white;
    width: 20px;
    height: 20px;

    &.black {
      fill: black;
    }
  }
}
