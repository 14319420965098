@import "buttons";
@import "utility";
@import "typography";
@import "variables";
@import "inputs";

:root {
  --color-deep-slate-blue: #4b5263;
  --color-deep-slate-blue-dark: #393d47;
  --color-burnt-sienna: #b47b4f;
  --color-burnt-sienna-dark: #a9734b;
  --color-burnt-sienna-darker: #956540;
  --color-darkpink: #a37b7a;
  --color-darkwhite: #f5f5f5;
  --color-white: #ffffff;
  --color-lightgrey: #e6e6e6;
  --color-grey: #bebebe;
  --color-darkgrey: #494949;
  --color-darkergrey: #3b3b3b;
  --color-red: #ff0000;

  --fs-smaller: 0.7rem;
  --fs-small: 0.875rem;
  --fs-regular: 1rem;
  --fs-mid: 1.25rem;
  --fs-large: 1.5rem;
  --fs-larger: 2rem;
  --fs-largest: 5rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}
