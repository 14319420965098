button.primary,
.button.primary {
  transition: all 0.05s linear 0s;
  border: 1px solid var(--color-deep-slate-blue);
  border-radius: 4px;
  background: var(--color-white);
  padding: 0.8rem;
  width: fit-content;
  color: var(--color-deep-slate-blue);
  font-weight: 800;
  text-transform: uppercase;
  box-shadow: 0 6px var(--color-darkwhite);
  margin-bottom: 6px;

  &:hover {
    background: var(--color-darkwhite);
  }

  &:active {
    box-shadow: 0 3px var(--color-darkwhite);
    transform: translateY(3px);
  }

  &:disabled {
    background: var(--color-darkgrey);
    color: var(--color-lightgrey);
    box-shadow: 0 6px var(--color-darkergrey);

    &:hover {
      background: var(--color-darkgrey);
    }
  }

  &.bottom-fixed {
    position: fixed;
    bottom: 5px;
    left: 43%;
  }

  &.bottom-left-fixed {
    position: fixed;
    bottom: 5px;
    left: 60%;
  }

  &.inverted {
    transition: all 0.05s linear 0s;
    background: var(--color-deep-slate-blue);
    color: var(--color-white);
    box-shadow: 0 6px var(--color-deep-slate-blue-dark);
    border: 1px solid var(--color-white);

    &:hover {
      background: var(--color-deep-slate-blue-dark);
    }

    &:active {
      box-shadow: 0 3px var(--color-deep-slate-blue-dark);
    }
    &:disabled {
      border: 1px solid var(--color-grey);
      background: none;
      color: var(--color-grey);
      box-shadow: none;

      &:hover {
        background: none;
      }
    }
  }
}

button:focus,
.button:focus {
  outline: 1px dashed;
}

button.text-button,
.button.text-button {
  background: none;
  border: none;
  transition: all 0.25s;
  cursor: pointer;
  color: var(--color-burnt-sienna);

  &:hover {
    color: var(--color-darkgrey);
  }
  &.white {
    color: var(--color-white);
    text-decoration: underline;
  }
}

button {
  cursor: pointer;
}
