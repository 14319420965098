.modal-overlay {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  z-index: 15;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  overflow: auto;
}

.modal-overlay .modal {
  background-color: var(--color-black);
  border: 1px solid var(--color-gray);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20rem;
}
